import SyncClient from 'twilio-sync';
import { useMemo } from 'react';
import { useAuth } from './auth';
import { createContext } from 'react';
import { useContext } from 'react';
import React from 'react';


export const SyncContext = createContext();


export function Sync({ children }) {
  const { sync } = useAuth();
  const client   = useMemo(() => sync && new SyncClient(sync), [sync]);

  return (
    <SyncContext.Provider value={client}>
      {children}
    </SyncContext.Provider>
  );
}


export function useSync() {
  return useContext(SyncContext);
}
