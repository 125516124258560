import * as SWRTC from '@andyet/simplewebrtc';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import './video.css';
import { useAuth } from './auth';
import { useGameData } from './game_data';


const store = SWRTC.createStore();


const apiKey = '5c2ee7d3d1131279f62182cf';
const CONFIG_URL = `https://api.simplewebrtc.com/config/user/${apiKey}`;



export default function Video({ game, users }) {
  const { simplewebrtc: userData } = useAuth();

  return (
    <ul className="video--participants">
      {userData && 
        <ReduxProvider store={store}>
          <SWRTC.Provider configUrl={CONFIG_URL} userData={userData}>
            <li>
              <Me {...{users}}/>
            </li>

            <SWRTC.Connected>
              <SWRTC.RequestUserMedia audio video auto />
              <SWRTC.RemoteAudioPlayer />
              {game && 
                <SWRTC.Room name={game.id} password={game.id}>
                  {({ room }) => {
                    return (
                      <SWRTC.PeerList
                        room={room.address}
                        render={({ peers, ...rest }) => {
                          return peers.map((peer, index) => {
                            return <SWRTC.RemoteMediaList
                              key={index}
                              peer={peer.address}
                              render={({ media }) => {
                                return (
                                  <li>
                                    <VideoContainer {...{users, customerData: peer.customerData, media}} />
                                  </li>
                                );
                              }}
                            />
                          });
                        }} />
                    );
                  }}
                </SWRTC.Room>
              }
            </SWRTC.Connected>
          </SWRTC.Provider>
        </ReduxProvider>
      }
    </ul>
  )
}


function Me({ users }) {
  return (
    <SWRTC.UserControls
      render={({
        isMuted,
        mute,
        unmute,
        isPaused,
        isSpeaking,
        isSpeakingWhileMuted,
        pauseVideo,
        resumeVideo,
        user,
        setDisplayName,
        customerData
      }) => (
        <SWRTC.LocalMediaList
          shared={true}
          render={({ media }) => {
            return <VideoContainer {...{users, customerData, media}} />;
          }}
        />
      )}
    />
  );
}


function VideoContainer({ users, game, customerData, media }) {
  const { sheet } = useGameData();
  const user = users && users.find(user => user.id === customerData.id);
  const name = user && user.name;
  const score = (user && sheet.getOverallScore(user.id));
  const videoStream = media
    .filter(m => m.kind === 'video')
    .filter(m => !m.remoteDisabled)
    .filter(m => !m.screenCapture)[0];

  return (
    <div className="participant">
      <div className="video">
        {videoStream && <SWRTC.Video media={videoStream} />}
      </div>
      <div className="details">
        <div className="name">{name}</div>
        <div className="score">{score}</div>
      </div>
    </div>
  );
}
