import { useEffect } from "react";
import { useGameID } from "./game";
import { v4 as uuid } from "uuid";
import { useState } from "react";
import { useContext } from "react";
import React from "react";
import { createContext } from "react";

export const AuthContext = createContext();

export function Auth({ children }) {
  const tokens = useTokens();

  return <AuthContext.Provider value={tokens}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}

export function useTokens() {
  const userID = useUserID();
  const [tokens, setTokens] = useState({});

  useEffect(() => {
    let unmounted = false;

    async function fetchToken() {
      const response = await fetch(
        `https://tuttifrutti.dimaion.com/api/token/${userID}`
      );
      const body = await response.json();
      if (!unmounted) setTokens(body);
    }

    fetchToken();

    return () => {
      unmounted = true;
    };
  }, [userID]);

  return {
    identity: tokens.identity,
    sync: tokens.sync,
    simplewebrtc: tokens.simplewebrtc,
  };
}

function useUserID() {
  const gameID = useGameID();
  const key = `user:${gameID}:id`;
  const [userID] = useState(() => {
    if (!localStorage[key]) localStorage[key] = uuid();
    return localStorage[key];
  });

  return userID;
}
