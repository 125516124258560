import { useSync } from './twilio';
import React from 'react';
import { useAuth } from './auth';
import { useMemo } from 'react';
import seedrandom from 'seedrandom';
import { useEffect } from 'react';
import { useState } from 'react';


const GameDataContext = React.createContext();


export function GameData({ gameID, children }) {
  const sync = useSync();
  const game = useGame({ gameID });
  const { identity } = useAuth();
  const [ user, users ] = useUsers({ gameID });
  const sheet = useSheet({ game });
  const isLoading = !(sync && game && users && sheet.sheet);

  const value = {
    isLoading, 
    sheet, 
    users, 
    user, 
    game, 
    sync
  };

  return (
    <GameDataContext.Provider value={value}>
      {children}
    </GameDataContext.Provider>
  );
}


export function useGameData() {
  return React.useContext(GameDataContext);
}


const allLetters = 'A B C D E F G H I J L M N O P R S T U V Z'.split(' ');


const topics = [
  'Nombres',
  'Colores',
  'Países',
  'Animales',
  'Comidas',
  'Marcas'
];


function useGame({ gameID }) {
  const sync = useSync();
  const [ document, setDocument ] = useState();
  const [ game, setGame ] = useState();

  useEffect(() => {
    if (sync) {
      sync.document(`game:${gameID}`)
        .then(doc => {
          setDocument(doc);
          setGame(doc.value);
        });
    }
  }, [ sync, gameID ]);

  useEffect(() => {
    if (document) {
      document.on('updated', ({ value }) => {
        setGame(value);
      });

      document.mutate(remoteData => {
        remoteData.status = remoteData.status || 'new';
        remoteData.round = remoteData.round || 0;
        remoteData.topics = remoteData.topics || topics;
        return remoteData;
      });
    }
  }, [ document ]);

  return useMemo(() => {
    const random  = seedrandom(gameID);
    const letters = shuffle(allLetters, random);

    async function updateTopics(topics) {
      if (game.topics.join('\n') !== topics.join('\n'))
        await document.update({ topics });
    }

    async function finishRound() {
      await document.update({ status: 'counting' });
    }

    async function nextRound() {
      await document.mutate(remoteData => {
        if (remoteData.round === game.round) {
          remoteData.status = 'writing';
          remoteData.round = game.round + 1;
          return remoteData;
        }
      });
    }

    async function start() {
      await document.mutate(remoteData => {
        if (remoteData.status === 'new') {
          remoteData.status = 'writing';
          return remoteData;
        }
      });
    }

    if (game) {
      return {
        updateTopics,
        finishRound,
        nextRound,
        start,
        letters,
        id: gameID,
        ...game
      };
    } else
      return null;
  }, [gameID, game, document]);
}


function useUser() {
  const { identity } = useAuth();
  const user = useMemo(() => (
    {
      id: identity,
      setName(name) {
      }
    }
  ), [ identity ]);
  return user;
}


function useUsers({ gameID }) {
  const [ users, setUsers ] = useState();
  const { identity: userID } = useAuth();

  const sync = useSync();
  // const user = useUser({ gameID });

  const [ map, setMap ] = useState();

  useEffect(() => {
    if (sync && gameID) {
      sync.map(`game:${gameID}:users`)
        .then(map => {
          setMap(map);
          map.getItems().then(page => {
            const toUsers = page.items
              .map(({ descriptor: { key, data } }) => ({ id: key, ...data }));
              setUsers(toUsers);
          });
        });
    }
  }, [sync, gameID]);

  useEffect(() => {
    let isMounted = true;

    if (map) {
      function updateUsers() {
        map.getItems().then(page => {
          const toUsers = page.items
            .map(({ descriptor: { key, data } }) => ({ id: key, ...data }));
          if (isMounted)
            setUsers(toUsers);
        });
      }

      map.on('itemAdded', updateUsers);
      map.on('itemUpdated', updateUsers);
    }

    return () => { isMounted = false };
  }, [ map ]);

  const user = useMemo(() => {
    if (users) {
      const user = users && users.find(u => u.id === userID);
      return {
        id: userID,
        name: user && user.name,
        async setName(name) {
          await map.update(userID, { name });
        }
      };
    } else
      return null
  }, [userID, users, map]);
  
  return [ user, users ];
}


function useSheet({ game }) {
  const [ sheet, setSheet ] = useState({});
  const [ document, setDocument ] = useState();
  const sync = useSync();
  const gameID = game && game.id;

  useEffect(() => {
    if (sync && game) {
      sync.document(`game:${game.id}:sheet`)
        .then(doc => {
          setDocument(doc);
          setSheet(doc.value);
        });
    }
  }, [sync, game, sheet]);

  useEffect(() => {
    if (document) {
      document.on('updated', ({ value }) => {
        setSheet(value);
      });
    }
  }, [document]);
  
  const scores = useMemo(() => getOverallScores(sheet), [ sheet ]);

  async function updateScore(userID, round, topicIndex, score) {
    await document.mutate(remoteData => {
      remoteData[round] = remoteData[round] || {};
      remoteData[round][userID] = remoteData[round][userID] || {};
      remoteData[round][userID][topicIndex] = remoteData[round][userID][topicIndex] || {};
      remoteData[round][userID][topicIndex].score = score;
      return remoteData;
    });
  }

  async function updateWord(userID, round, topicIndex, word) {
    await document.mutate(remoteData => {
      remoteData[round] = remoteData[round] || {};
      remoteData[round][userID] = remoteData[round][userID] || {};
      remoteData[round][userID][topicIndex] = remoteData[round][userID][topicIndex] || {};
      if (remoteData[round][userID][topicIndex].word !== word) {
        remoteData[round][userID][topicIndex].word = word;
        return remoteData;
      }
    });
  }

  function getScore(userID, round, topicIndex) {
    const score = sheet[round] && 
      sheet[round][userID] &&
      sheet[round][userID][topicIndex] &&
      sheet[round][userID][topicIndex].score;
    return score;
  }

  function getWord(userID, round, topicIndex) {
    const word = sheet[round] && 
      sheet[round][userID] &&
      sheet[round][userID][topicIndex] &&
      sheet[round][userID][topicIndex].word;
    return word;
  }

  function getOverallScore(userID) {
    return scores[userID] || 0;
  }

  return useMemo(() => {
    if (sheet) 
      return {
        getScore,
        getWord,
        updateScore,
        updateWord,
        getOverallScore,
        sheet
      };
    else
      return null;
  }, [sheet]); // eslint-disable-line
}


function shuffle(original, random) {
  const array = original.slice();
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}


function getOverallScores(sheet) {
  const scores = {};

  for (const [ , users ] of Object.entries(sheet)) {
    for (const [ userID, rounds ] of Object.entries(users)) {
      const score = Object.values(rounds)
        .map(({ score }) => score || 0)
        .reduce((accum, score) => accum + score, 0);
      scores[userID] = scores[userID] || 0;
      scores[userID] += score;
    }
  }

  return scores;
}
