import React from 'react';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useEffect } from 'react';
import { useRef } from 'react';
import './App.css';
import { useDebounce } from 'use-debounce';
import Video from './video';
import { Auth } from './auth';
import { useGameID } from './game';
import { ReactComponent as Logo } from './salad.svg';
import { GameData } from './game_data';
import { useGameData } from './game_data';
import { Sync } from './twilio';
import Modal from 'react-modal';


const topics = [
  'Nombres',
  'Colores',
  'Países',
  'Animales',
  'Comidas',
  'Marcas'
];


function App() {
  return (
    <Auth>
      <Sync>
        <Start />
      </Sync>
    </Auth>
  );
}


function Start() {
  const gameID = useGameID();

  const isGame = !!gameID;

  if (isGame)
    return (
      <GameData gameID={gameID}>
        <StartGame />
      </GameData>
    );
  else
    return <Home />;
}

function Home() {
  function createNewGame() {
    const id = uuid();
    window.location = `/${id}`;
  }


  return (
    <div className="home">
      <div className="home--container">
        <div className="home--logo">
          <Logo />
        </div>

        <div className="home--box">
          Jugá al tutti frutti con amigues,
          <br />
          video y audio incluidos.
          <br />
          <br />
          <button onClick={createNewGame}>Crear Partida</button>
        </div>

        <div className="home--disclaimer">
          No hace falta registrarse, y no recolectamos ninguna información
          privada ni tuya ni de las personas que invites a jugar.
        </div>
      </div>
    </div>
  );
}

function StartGame() {
  const { gameID, isLoading, game, user, users, sync, sheet } = useGameData();

  return (
    <>
      <GetUserName {...{user, users}} />
      <div className="room-container">
        {<Video {...{game, sheet, users}}/>}

        <div>
          {isLoading && <Loading />}
          {!isLoading && <Game {...{sync, game, user, users, sheet}} />}
        </div>
      </div>
    </>
  );
}

function GetUserName({ user, users }) {
  const isOpen = !!(user && !user.name);
  const inputRef = useRef();

  async function startGame(event) {
    event.preventDefault();
    event.target.querySelector('button').disabled = true;

    const name = inputRef.current.value;
    await user.setName(name);
  }

  return (
    <Modal isOpen={isOpen} className="modal--content" overlayClassName="modal--overlay">
      <div>
        <Logo />
      </div>

      <h2>¿Cómo te llamás?</h2>
      <p>
        (No tiene que ser tu nombre real,
        y solo vale para esta partida.)
      </p>

      <form onSubmit={startGame}>
        <input type="text" ref={inputRef} />

        <button type="submit" className="button-large">Aceptar</button>
      </form>
    </Modal>
  );
}

function Loading() {
  return (
    <div className="game-new">
      <Spinner />
      <h1>Conectando...</h1>
    </div>
  );
}

function Game({ sync, game, user, users, sheet }) {
  const isNew = (game.status === 'new');

  return (
    <div>
      {isNew && <NewGame {...{sync, game, user, users}} />}
      {!isNew && <InProgressGame {...{sync, game, user, users, sheet}} />}
    </div>
  );
}


function NewGame({ sync, game, user, users }) {
  async function startGame(event) {
    event.target.disabled = true;
    await game.start();
  }

  const [ topicsString, setTopicsString ] = useState(() => game.topics.join('\n'));
  const [ debouncedTopicsString ]         = useDebounce(topicsString, 300);

  useEffect(() => {
    const topics = debouncedTopicsString
      .split('\n')
      .map(s => s.trim())
      .filter(Boolean);

    game.updateTopics(topics);
  }, [ debouncedTopicsString, game ]);


  async function onTopicsChange(event) {
    const newTopics = event.target.value;
    setTopicsString(newTopics);
  }

  const url = window.location.href;

  return (
    <div className="game-new">
      <Spinner />
      <h1>Esperando amigues...</h1>
      <p>
        Compartí el link de esta página para invitar
        más gente.

        <br />
        <input type="text" value={url} className="share-url" disabled />
        <br />

        Cuando quieran, pueden empezar a jugar. 
        <br />
        Se pueden sumar más amigues con el juego comenzado.
        <br />
        <br />
        Categorías (una por línea):
        <br />
        <textarea value={topicsString} onChange={onTopicsChange} />
      </p>

      <button className="button-large" onClick={startGame}>Empezar a jugar</button>
    </div>
  );
}


function InProgressGame({ sync, game, user, users, sheet }) {
  async function done(event) {
    await game.finishRound();
  }

  async function next() {
    await game.nextRound();
  }

  const isWriting = (game.status === 'writing');
  const isCounting = (game.status === 'counting');

  return (
    <>
      <div className="sheet">
        <table>
          <thead>
            <tr>
              <th className="letter">Letra</th>
              {game.topics.map((topic, index) => (
                <th key={index}>{topic}</th>
              ))}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {[...Array(game.round + 1)].map((_, index) => {
              const round = game.round - index;
              const isCurrentRound = (round === game.round);

              return (
                <tr key={round}>
                  <td>
                    <span className="letter--container">
                      {!isCurrentRound && game.letters[round]}
                      {isCurrentRound && <SpinningLetter current={game.round} all={game.letters} />}
                    </span>
                  </td>
                  {game.topics.map((topic, index) => (
                    <td key={index}>
                      {!isCurrentRound && 
                        (sheet.getWord(user.id, round, index) || '—')}
                      {isCurrentRound &&
                        <TopicInput isCounting={isCounting} 
                          {...{isCounting, user, sync, game, sheet, index}} />
                      }
                    </td>
                  ))}
                  <td>
                    {isCurrentRound &&
                      <button onClick={done} disabled={isCounting}>¡Listo!</button>}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="sheet--next" data-visible={!isWriting}>
        Cuando todes terminen de anotarse los puntos,
        pueden pasar a la siguiente letra.
        <button onClick={next} disabled={isWriting}>Siguiente letra →</button>
      </div>
    </>
  );
}


function TopicInput({ isCounting, sync, game, user, sheet, index, setScore }) {
  const { round } = game;

  async function onChange(score) {
    await sheet.updateScore(user.id, round, index, score);
  }

  const initialValue = sheet.getWord(user.id, round, index);
  const [ word, setWord ] = useState(initialValue || '');
  const [debouncedWord] = useDebounce(word, 1000);
  useEffect(() => {
    sheet.updateWord(user.id, round, index, debouncedWord);
  }, [debouncedWord, round, index, user.id, sheet]);

  const score = sheet.getScore(user.id, round, index);
  const inputRef = useRef();

  useEffect(() => {
    if (index === 0)
      inputRef.current.focus();
  }, [index]);

  return (
    <div className="topic-input">
      <input ref={inputRef} type="text" value={word} disabled={isCounting} 
        onChange={e => setWord(e.target.value)}/>
      {isCounting && <TopicScore score={score} onChange={onChange} />}
    </div>
  );
}


const topicScores = [ 0, 5, 10, 20 ];

function TopicScore({ score: currentScore, onChange }) {
  return (
    <div className="topic-scores">
      {topicScores.map(score => {
        const isCurrent = currentScore === score;
        return (
          <span key={score} 
            className={isCurrent ? 'current' : null}
            onClick={() => onChange(score)}>{score}</span>
        );
      })}
    </div>
  );
}


function SpinningLetter({ current, all }) {
  function setCurrentLetter(event) {
    event.target.style.top = `${-1 * (current)}em`;
    event.target.classList.toggle('animate');
  }

  const letterSpinnerElement = useRef(null);
  useEffect(() => {
    letterSpinnerElement.current.classList.toggle('animate');
  }, [ current ]);

  return (
    <div className="letter-spinner"><span ref={letterSpinnerElement} onAnimationEnd={setCurrentLetter}>{all.join(' ')}</span></div>
  );
}


function Spinner() {
  return <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>;
}


export default App;
